.methodBody{
    padding-left: 20px;
}

.methodTitle{
    display: flex;
}
.methodTitle .icon{
    margin-right: 5px;
}

@media (max-width: 500px){
    .methodBody{
        padding-left: 0px;
    }
}