.checkIcon {
    color:var(--get-color);
}
.super{
    display: none;
}
.apiUsers td{
    height: 35px;
}
.apiUsers td .icon{
    vertical-align: bottom;
}
@media (max-width: 700px){
    .hideSmall{
        display: none;
    }
    .super{
        display: block;
    }
    .superUser{
        display: none;
    }
}