.appTags{
    display: flex;
    padding: 10px;
    border-radius: 10px;
    overflow-x: auto;
}
.pubApp .appDesc{
    margin-top: 0px;
}
.appHeader{
    display: flex;
}
.appTags .appTag{
    margin-right: 10px;
    background-color: rgba(0,0,0,0.1);
    padding: 10px;
    border-radius: 50px;
}
.limitTag{
    margin-left: 10px;
}

.appPermission{
    box-shadow:2px 2px 5px 3px rgba(0,0,0,.1);
    /* padding: 10px; */
    background-color: #eaf8ef;
}

.permissionTitle{
    /* background-color: #edfaf7; */
    padding: 10px;
    padding-bottom: 0px;
}
.permissionTitle .icon {
    font-size: 20px;
}
.permissionItems{
    overflow-x: auto;
    padding-top: 0px;
    padding: 10px;
    padding-left: 20px;
    padding-bottom: 20px;
}
.permissionItem{
    line-height: 30px;
    margin-right: 10px;
    background-color: rgba(0,0,0,.05);
    padding: 10px;
    border-radius: 50px;
}

.pubApp .appSelect{
    display: none
}

@media (max-width: 850px){
    .pubApp .appSelect{
        display: block;
    }
}