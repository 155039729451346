.inlineCode{
    background-color: var(--light-grey-color);
    padding: 2px;
    border: 1px solid var(--border-grey-color);
    color: rgb(255, 53, 73);
    font-weight: 500;
    border-radius: 5px;
}
.codeBox{
    box-sizing: border-box;
    position: relative;
    margin-top:20px;
    margin-bottom: 20px;
    box-shadow:2px 2px 2px 0px rgba(0,0,0,.5);
}

.codeBox pre {
    white-space: pre-wrap;       /* css-3 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
    max-height: 400px;
    line-height: 20px;
}

.codeHeader{
    display: flex;
    padding: 0px 10px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    font-size: 14px;
    background-color: var(--term-header);
    color:var(--light-grey-color);
}
.codeBoxTitle{
    line-height: 33px;  
}
.codeBoxCopy{
    cursor: pointer;
    margin-left: auto;
    display: flex;
    border-radius: 5px;
    width: fit-content;
}
.notClicked{
    margin-right: 5px;
    transform: rotate(90deg);
}
.codeBoxCopyText{
    margin-right: 8px;
    line-height: 33px;  
}
.codeBoxCopy:hover{
    background-color: var(--term-header-highlight);
}
.simpleCopy{
    cursor: pointer;
    position: absolute;
    border-radius: 5px;
    right:0;
    margin: 5px;
    padding: 5px;
    padding-right: 2px;
    color:var(--light-grey-color);
    z-index: 10;
}
.simpleCopy:hover{
    background-color: rgba(255,255,255,.05);
    color:white;
}

.codeBox .material-symbols-outlined {
    font-variation-settings:
    'wght' 200,
    'opsz' 14
}

.extendBox{
    display: none;
}
@media (max-width: 1200px){
    .wrapBox{
        display: none;
    }
    .extendBox{
        display: block;
    }
  }