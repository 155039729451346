.routeResponse{
    display: flex;
}
.responseCodes ul{
    padding: 0px;
}
.responseCodesHeader h4{
    margin-bottom: 0px;
}
.routeResponses{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 10px;
}
.statusCode{
    height: fit-content;
    border-radius: 5px;
}

@media (max-width: 1000px){
    .routeResponses{
        grid-template-columns: 1fr 1fr 1fr;
    }
}
@media (max-width: 650px){
    .routeResponses{
        grid-template-columns: 1fr 1fr;
    }
}