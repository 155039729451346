.tag {
    display: block;
    padding:3px;
    font-weight: 500;
    width: fit-content;
    border-radius: 5px;
    margin-bottom: 10px;
}

.tagWarning{
    background-color: var(--warning-highlight);
    color: var(--dark-warning-color);
}

.tagPrimary{
    background-color: var(--primary-highlight);
    color: var(--primary-color);
}
.tagSuccess{
    background-color: var(--success-highlight);
    color: var(--success-color);
}

.tagDanger{
    background-color: var(--danger-highlight);
    color: var(--danger-color);
}

.tagNeutral{
    background-color: var(--border-grey-color);
    color: var(--vdark-grey-color);
}

.tagDefault{
    background-color: var(--border-grey-color);
    color: var(--vdark-grey-color);
}