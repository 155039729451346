
.authForm {
    max-width: 400px;
    margin: auto;
    margin-top: 2rem;
    padding: 40px;
    margin-bottom: 300px;
}

.authTitle {
    text-align: start;
}

.authForm .btn, .updateForm .btn {
    width: 100%;
    height: 50px;
    background-color: var(--primary-color);
    color:white;
    border: 0px;
    font-size: 20px;
    cursor: pointer;
    margin-bottom: 5px;
}
.authForm .btn:hover, .updateForm .btn:hover {
    background-color: var(--primary-color-light)
} 

.authForm input, .updateForm input, .authForm textarea {
    width: 100%;
    height: 45px;
    box-sizing: border-box;
    padding-left: 10px;
    font-size: 18px;
}
.authForm textarea {
    height: 100px;
    padding: 10px;
    overflow-y: auto;
}

.auth-container, .updateForm{
    width: 100%;
}

label span {
    display: block;
    margin-bottom: 6px;
    color: var(--mid-grey-color)
}

.authForm label, .updateForm label {
    text-align: left;
    display: block;
    margin: 20px auto;
}

.authFooter {
    box-sizing: border-box;
    padding: 20px;
    width: 100%;
    align-items: center;
    color: var(--dark-grey-color);
    display: flex;
}
.flushTop{
    padding-top: 0px;
    margin-top: 0px;
  }
.authFooter a{
    font-size: 16px;
    color:var(--primary-color);
    margin-top:auto;
    margin-bottom: auto;
    margin-right: auto;
}
.authFooter a:hover{
    color:var(--primary-dark-color)
}
.authFooter p {
    margin: auto;
    margin-right: 5px;
}
.authFooter .loneLink{
    margin-left: auto;
}
.updateForm .btn {
    background-color: var(--mid-grey-color);
}
.updateForm .btn span{
    vertical-align: middle;
}
.updateForm .btn:hover {
    background-color: var(--mid-grey-highlight);
}

.floatRight {
    float: right;
}

.updateForm .btnPrimary {
    background-color: var(--primary-color);
}
.updateForm .btnPrimary:hover{
    background-color: var(--primary-color-light);
}

.updateForm .btnDanger {
    background-color: var(--danger-flat);
}
.updateForm .btnDanger:hover{
    background-color: var(--danger-flat-light);
}

.margBottom{
    margin-bottom: 20px;
}
.margTop{
    margin-top: 20px;
}

.emailTag{
    text-align: center;
}