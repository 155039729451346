.Iframe{
    text-align: left;
}
.Iframe .appDoc{
    background-color: white;
}
.Iframe .apiDocHeader{
    margin-top: 0px;
}
.Iframe .routeHeaderInfoBottom{
    margin-bottom: 0px;
}