.overNav{
    text-align: left;
    display: none;
    position: fixed;
    background-color: white;
    border-bottom: 1px solid var(--border-grey-color);
    margin-top: 61px;
    /* top: 60; */
    left: 0;
    right: 0;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}
.overNav .navBanner{
    padding: 10px;
    text-align: end;
}
.overNav ul{
    margin: 0px;
}
.overNav .navBody .navLink{
    /* padding: 20px; */
    display: block;
    padding: 20px;
    width: 100%;
    background-color: var(--primary-color);
    font-weight: 600;
    color:rgba(255,255,255,0.8);
    font-size: 1.5rem;
    border-bottom: 1px solid rgba(255,255,255,.3)
}
.overNav .navBody .navLink:hover{
    color: white;
}
.navBody ul{
    padding-left: 0px;
}
.overNav .navClose{
    /* background-color: blue; */
    align-self: end;
}
.overNav .navBody .navTitle{
    cursor: pointer;
    display: flex;
    align-items: center;
}
.iconHide{
    color: var(--primary-color);
}
.overNav .navBody .subLink{
    box-sizing: border-box;
    font-size: 1rem;
    padding: 0px;
    text-align: center;
    /* text-align: left; */
    background-color: var(--primary-color-light);
    border-left: 1px solid rgba(255,255,255,.3);
}
.overNav .navBody .subLink{
    padding: 10px;
}
.subList{
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.navTitle .icon{
    padding-right: 5px;
    position:relative;
}
.overNav .navBody .subList .linkHide{
    color: var(--primary-color-light);
    cursor: default;
    height: 100%;
}
@media (max-width: 850px){
    .overNav{
        display: block;
    }
}