.footer{
    padding-top: 40px;
    background-color: var(--vdark-grey-color);
    min-height: 250px;
    color: var(--light-grey-color);
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.footer ul{
    padding: 0px;
}
.footerTitle{
    font-size: 20px;
    margin-bottom: 10px;
}
.footerLinks{
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr 1fr;
}
.footerLink{
    padding: 2px;
}
.footerLinks a{
    color: var(--mid-light-grey-color);
}
.footerLinks a:hover{
    color: white;
}


@media (max-width: 850px){
    .footerLogo {
        display: none;
    }
    .footer{
        grid-template-columns: 1fr;
    }
}