.galleryContainer tr {
    height: 4rem;
}
.galleryContainer table{
    width: 100%;
}
.galleryContainer .appName{
    font-weight: 700;
    padding: 5px;
}
.galleryContainer .appDesc{
    white-space: nowrap;
    color: var(--mid-grey-color);
    overflow: auto;
    max-width: 300px;
    overflow-x: hidden;
}

.galleryContainer table {
    border-collapse: collapse;
  }
  
.galleryContainer tr {
    border-bottom: 1px solid rgba(0,0,0,0.2);
    height: 4rem;
}
.galleryContainer tbody tr:hover{
    cursor: p;
    background-color: rgba(0,0,0,0.05);
    cursor: pointer;
    box-shadow:2px 2px 3px 0px rgba(0,0,0,.2);
}
.appName{
    /* padding-left: 10px; */
}

.subLink{
    background-color: var(--primary-highlight);
}
.galleryContainer .subLink:hover{
    background-color: var(--primary-highlight-hover);
}

.gallaryForm{
    display: flex;
    background-color: rgba(0,0,0,0.05);
    padding: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid rgba(0,0,0,.2);;
}
.formButtons{
    display: flex;
}
.gallarySearch{
    box-sizing: border-box;
    background-color: white;
    display: flex;
    margin-left: auto;
    width: fit-content;
    padding: 10px;
}
.gallarySearch input{
    font-size: 18px;
    border: 0px;
}
.gallarySearch .icon:hover{
    background-color: rgba(0,0,0,.1);
    border-radius: 5px;
    cursor: pointer;
}

.formButtons .icon{
    cursor: pointer;
    /* color: white; */
    font-weight: 600;
    padding: 7px;
    border-radius: 5px;
    margin: auto;
    vertical-align: center;
    font-size: 20px;
    color: var(--primary-color-light);;
    border: 2px solid var(--primary-color-light);
    margin-right: 10px;
}
.formButtons .icon:hover{
    color: white;
    background-color: var(--primary-color-light);
}

.subLink{
    white-space: nowrap;
}
@media (max-width: 500px){
    .gallarySearch input{
        width: 150px;
    }
    .authorCol{
        display: none;
    }
    .gallaryForm .formButtons .createIcon{
        display: none;
    }
    
}