.Documentation{
    display: flex;
    justify-content: center;
}
.flexDiv{
    display: flex;
    width: 100%;
    justify-content: center;
    background-color: rgb(250,250,250);
}
.apiDocContainer .pageNav{
    background-color: rgb(250,250,250);
}
.routeHeader{
    background-color: rgba(0,0,0,.025);
    padding-bottom: 10px;
}
.apiDoc .tableTitle {
    padding: 10px;
}
.appIndex .apiDoc {
    max-width: 1000px;
    padding: 0px 0px;
    margin: auto;
}
.get{
    color:var(--success-color);
    background-color: var(--success-highlight);
}
.post{
    margin-left: 5px;
    color:var(--primary-color);
    background-color: var(--primary-highlight);
}
.put{
    margin-left: 5px;
    color:var(--dark-warning-color);
    background-color: var(--warning-highlight);
}
.delete{
    margin-left: 5px;
    color:var(--danger-color);
    background-color: var(--danger-highlight);
}
.successTitle {
    border-left: 3px solid var(--success-color);
    background-color: var(--success-highlight);
    color: var(--success-color);
}

.primaryTitle {
    border-left: 3px solid var(--primary-color);
    background-color: var(--primary-highlight);
    color: var(--primary-color);
}
.warningTitle {
    border-left: 3px solid var(--warning-color);
    background-color: var(--warning-highlight);
    color: var(--warning-color);
}
.dangerTitle {
    border-left: 3px solid var(--danger-color);
    background-color: var(--danger-highlight);
    color: var(--danger-color);
}
.darkTitle {
    background-color: var(--light-grey-color);
    /* color: white; */
    padding: 20px;
}

.table .flushLeft{
    padding: 0px;
}

.flexTitle .icon{
    padding-right: 10px;
    justify-self: end;
    vertical-align: bottom;
    padding-bottom: 2px;
}


.deleteTitleContainer{
    margin-top: 40px;
}

.errorItem{
    /* border-top: 1px solid var(--mid-grey-color); */
    padding-top: 10px;
    padding-bottom: 10px;
    padding:10px;
}
.errorItemEven{
    background-color: rgba(0,0,0,.1)
}
.errorItem p{
    padding-left: 20px;
}
.method{
    font-weight: 600;
    padding: 5px;
}
.releaseDate{
    float: right;
}
.roadmap .primaryTitle{
    padding: 10px;
}
.release .center, .roadmap .center{
    vertical-align: middle;
}
.release .icon, .roadmap .icon{
    padding-right: 15px;
}
.greenIcon{
    color:var(--get-color)
}
.yellowIcon{
    color:var(--dark-warning-color)
}
.primaryIcon{
    color:var(--primary-color)
}
.releaseList .subul {
    padding-left: 0px;
}
.smallText{
    font-size: 16px;
}
.howTo pre{
    line-height: 20px;
}
.serializationNotes{
    list-style-type: disc
}
.serializationNotes li{
    padding: 3px;
}
.routeTitle{
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-color: rgb(107, 74, 252);
    padding: 20px;
    color:white;
}
.howTo .codeBox {
    margin-top: 40px;
    margin-bottom: 40px;
}
.exampleAscii{
    text-align: center;
}
.pagination .codeBox pre{
    max-height: 800px;
}

.release .releaseNotes p {
    margin: 5px;
}
.release .releaseNotes {
    margin-bottom: 20px;
}

.howTo .sourceCodeBtn{
    display: flex;
    box-sizing: border-box;
    padding: 6px;
    border-radius: 20px;
    border: 1px solid var(--primary-color);
    box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.2);
    /* position: relative; */
    
}
.howTo .sourceCodeBtn:hover{
    background-color: var(--primary-color);
    color: white;
}

.sourceCodeBtn i {
    padding-right: 5px;
    padding-left: 10px;
    font-size: 20px;
    margin-top: 3px;
}
.sourceCodeBtn span{
    padding-right: 10px;
}

.Article ul{
    padding-left: 0px;
}

.startLink{
    display: block;
    border: 1px solid var(--primary-color-light);
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 50px;
    color: var(--primary-color);
    margin-top: 20px;
    margin-bottom: 30px;
    width: fit-content;
}
.startLink:hover{
    background-color: var(--primary-color-light);
    color: white;
}

ol li {
    line-height: 30px;
}
@media (max-width: 1230px){
    .flexDiv .pageNav{
        display: none;
    }
    .flexDiv{
        display: block;
    }
}

@media (max-width: 1200px){
    .pageNav{
        display: none;
    }
    .Documentation{
        display: block;
    }
}
@media (max-width: 850px){
    .Article ul{
        padding-left: 0px;
    }
    .Article ol{
        padding-left: 30px;
    }
    .serializationNotes{
        /* list-style-position: inside; */
    }
}