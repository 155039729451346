.routeParams{
    margin-top: 20px;
    box-shadow:2px 2px 10px 0px rgba(0,0,0,.2);
}
.routeParams ul{
    margin:0px;
}
.routeParams li{
    padding: 5px;
}
.routeParamsHeader{
    padding: 15px;
    /* padding-bottom: 10px; */
    border-bottom: 1px solid rgba(0,0,0,.1);
}
.routeParamsHeader h4{
    display: flex;
    margin: 0px;
    align-items: center;
}
.routeParamsHeader .icon{
    padding-right: 10px;
}
.routeParamsBody{
    padding-top: 0px;
    padding: 15px;
    /* padding-top: 5px; */
    padding-left: 25px;
}
.paramsList{
    padding: 0px;
    line-height: 25px;
}
.requiredTag{
    color:rgb(201, 156, 21);
    background-color: var(--warning-highlight);
}
.paramName{
    font-weight: 650;
}