.userInfo{
    text-align: left;
    padding-left: 10px;
    padding-right: 10px;
    display: block;
}
.updateForm .value{
    margin-left: 10px;
}
.updateForm .articleText{
    margin-top: 30px;
    margin-bottom: 30px;
}