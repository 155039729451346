.pageNavContainer{
    margin-top: 80px;
    padding-top: 12px;
    white-space: nowrap;
    border-left: 2px solid rgb(200,200,200);
    /* height: fit-content; */
    text-align: left;
    position: sticky;
    top: 92px;
    min-width: 200px;
    overflow-x: hidden;
}
.pageNav ul{
    padding: 15px;
    padding-top: 0px;
}
.pageNav a:active{
    color: var(--primary-color);
}
.pageNav .pageNavLinks{
    padding-top: 0px;
    margin-top: 0px;
}
.pageNav li{
    padding-bottom: 10px;
}
.pageNavHeading{
    display: none;
}
.pageNavLinks{
    margin: 0px;
    padding: 0px;
}
/* .pageNavLinks .active{
    color: var(--primary-color) !important;
} */

.nil{
    padding: 0px;
    margin: 0px;
    height: 0px;
}