.infoBox{
    background-color: var(--link-hover-color);
    border: 1px solid var(--border-grey-color);
    border-radius: 2px;
    padding:20px;
    margin-bottom: 20px;
}
.infoContainer{
    display: flex;
}
.infoIcon{
    color: var(--mid-grey-color);
    font-size: 21px;
    margin-right: 15px;
    padding-top: 2px;
}
.infoBoxYellow{
    background-color: var(--warning-highlight);
    border: 1px solid var(--warning-border);
}
.infoBoxYellow .articleText{
    color: var(--darker-warning-color);
}
.infoBoxYellow .infoIcon{
    color: var(--warning-color);
}

.infoBox .material-symbols-outlined {
  font-variation-settings:
  'FILL' 1,
  'GRAD' 0,
  'opsz' 48
}

.infoBoxPrimary{
    background-color: var(--primary-highlight);
    border: 1px solid var(--primary-highlight);
}
.infoBoxPrimary .infoIcon {
    color: var(--primary-color);
}
.infoBoxPrimary .articleText{
    color: var(--primary-dark-color)
}