.routeHeaderInfo{
    background-color: white;
    border-radius: 5px;
    box-shadow: 2px 2px 10px 0px rgba(0,0,0,0.2);
    margin-bottom: 30px;
    padding-bottom: 10px;
}
.lastRoute{
    padding-bottom: 0px;
    margin-bottom: 0px;
}
.routeHeaderBody{
    padding: 20px;
}
.routeHeaderHead{
    background-color: rgb(107, 74, 252);
    color: white;
    padding: 20px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.routeHeaderHead h3{
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
}