.keyTable{
    box-sizing: border-box;
    width: 100%;
    font-size: 1rem;
    border-radius: 5px;
    padding: 20px;
    background-color: var(--link-hover-color);
    margin-top: 30px;
    margin-bottom: 30px;
    max-width: 100%;
}
.secretBlock{
    overflow-x: auto;
    max-width: 200px;
}
thead tr th {
    border-bottom: 1px solid var(--border-grey-color); 
    border-collapse: separate; 
    padding: 5px;
}

tbody tr td {
    padding: 5px;
}

.visibilityIcon, .apiLinkCopy{
    float: left;
}

.rowText{
    display: flex;
    align-items: center;
    width: fit-content;
    /* margin: auto; */
}

.apiLinkCopy {
    /* box-sizing: border-box; */
    display: block;
    margin: 0px;
    line-height: 25px;
}

.apiLink{
    cursor: pointer;
    padding: 5px;
    border-radius: 5px;
    color: var(--primary-color);
    /* border: 1px solid red; */
}

.apiLink:hover{
    background-color: rgba(0,0,0,0.05)
}

.buttonLinks{
    display: flex;
    margin: 20px auto;
}

.daily{
    display: none;
}

.padModal{
    padding: 10px 20px;
    line-height: 30px;
}
@media (max-width: 850px){
    .keyColumn{
        max-width: 90px;
    }
    .dailyUsage{
        display: none;
    }
    .daily{
        display: block;
    }
}