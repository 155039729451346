.feedNav{
    width: fit-content;
    margin-left:auto;
    padding: 10px;
    /* height: 25px; */
}
.feedNav span {
    line-height: 40px;
    vertical-align: middle;
}
.feedNav .pageNavIcon{
    cursor: pointer;
}
.feedNav .pageNavIcon:hover{
    background-color: var(--light-grey-color);
}
.feedNav .inactiveIcon{
    cursor: default;
    color: rgba(0,0,0,.4);
}
.feedNav .inactiveIcon:hover{
    background-color: rgba(0,0,0,0);
}

.feedNav .pageNumber{
    margin-left: 5px;
    margin-right: 5px;
}

.feedNav .pageInfo{
    margin-right: 5px;
}
table .tag{
    margin-top: auto;
    margin-bottom: auto;
}
.feed td{
    padding-top: 10px;
    padding-bottom: 10px;
}