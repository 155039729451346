.codeExample{
 background-color: rgba(0,0,0,.05);
 padding: 5px;
 overflow-y: auto;
 overflow-x: auto;
 line-height: 16px;
 font-size: 15px;
}
.codeExampleContent{
    /* padding-left: 10px;
    padding-right: 10px; */
}
.codeExample p, .codeExample code{
    box-sizing: border-box;
    display: inline-block;
    padding: 8px;
    width: 100%;
    
}