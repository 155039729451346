.Article{
    padding: 60px 40px;
    margin-left: 245px;
    text-align: left;
    color: var(--vdark-grey-color)
}
.splitArticle .articleBody{
    padding: 60px 30px;
    padding-left: 275px;
    padding-top: 0px;
    text-align: left;
    color: var(--vdark-grey-color);
    background-color: rgb(250,250,250);
}
.appIndex .splitArticle .articleBody {
    padding-top: 20px;
}
.splitArticle .articleHead{
    background-color: rgb(250,250,250);
    padding: 60px 40px;
    padding-bottom: 0px;
    padding-left: 285px;
    text-align: left;
    color: var(--vdark-grey-color);
}
.splitArticle{
    background-color: rgb(250,250,250);
}
.ArticleNoNav{
    margin-left: 0px;
}

.Iframe .Article{
    margin-left: 0px;
    padding: 0;
}
.articleCentered{
    max-width: 900px;
    padding: 0px 0px;
    margin: auto;
}

.Article .codeBlock{
    width: 100%;
}
.articleTitle{
    font-size: 2rem;
    color: var(--vdark-grey-color)
}
.articleMidTitle{
    font-size: 1.75rem;
    color: var(--vdark-grey-color)
}
.articleSubTitle{
    font-size: 1.5rem;
    color: var(--vdark-grey-color)
}

.articleText{
    font-size: 17px;
    line-height: 30px;
    color: var(--vdark-grey-color);
    /* max-width: 950px; */
}
.articleTextSmall{
    font-size: 16px;
    color: var(--vdark-grey-color);
    /* max-width: 950px; */
}
@media (max-width: 850px){
    .Article, .splitArticle .articleBody, .splitArticle .articleHead{
        padding-left: 40px;
        margin-left: 0px;
    }
}
@media (max-width: 650px){
    .Article{
        padding: 60px 20px;
    }
    .splitArticle .articleBody {
        padding: 60px 20px;
        padding-top: 0px;
    }
     .splitArticle .articleHead{
        padding: 60px 20px;
        padding-bottom: 0px;
     }
}
@media (max-width: 415px){
    .Article{
        padding: 60px 20px;
    }
    .splitArticle .articleBody{
        padding: 60px 20px;
        padding-top: 0px;
    } 
    .splitArticle .articleHead {
        padding: 60px 20px;
        padding-bottom: 0px;
    }
}