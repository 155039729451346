.sideNav{
    color: var(--mid-grey-color);
    background-color: white;
    z-index: 5;
    text-align: left;
    width:245px;
    border-right: 1px solid var(--border-grey-color);
    position: fixed;
    height: 100%;
    overflow-y: auto;
    box-shadow: 2px 2px 10px 0px rgba(0,0,0,0.2);
}
.sideNavContainer{
    margin-top: 100px;
}
.sideNavSection{
    margin: 40px auto;
}
.sideNavLinks{
    padding-left: 0px;
    padding-top: 5px;
    margin:7px auto;
}
.subSideNavLinks{
    padding-left: 0px;
    /* margin:7px auto; */
}
.subSideNavLink{
    border-radius: 5px;
    box-sizing: border-box;
    display: block;
    width: 95%;
    height: 100%;
    padding: 8px 0px;
    padding-left: 50px;
    margin: auto;
    margin-top: 2px;
}
.sideNavLink{
    border-radius: 5px;
    box-sizing: border-box;
    display: block;
    width: 95%;
    height: 100%;
    padding: 8px 0px;
    padding-left: 30px;
    margin: auto;
    margin-top: 2px;
}
.sideNavLinks .activeRoute{
    background-color: var(--primary-highlight);
}
.subSideNavLinks .activeRoute{
    background-color: var(--link-hover-color);
}
.sideNavLinks .activeRoute:hover{
    color: var(--primary-color);
    background-color: var(--primary-highlight);
}
.sideNavLink:hover, .subSideNavLink:hover, .subSideNavLinks .activeRoute:hover{
    background-color: var(--link-hover-color);
}

.sideNavHeading{
    color:var(--dark-grey-color);
    font-weight: 700;
    padding-left: 30px;
}

@media (max-width: 850px){
    .sideNav{
        display: none;
    }
}