.exampleBox{
    box-sizing: border-box;
    background-color: var(--light-grey-color);
    border-radius: 5px;
    font-size: 14px;
    padding:5px 20px;
    margin-top:20px;
    position: relative;
}
.exampleBox .simpleCopy{
    position:absolute;
    top: 0;
    right: 0;
    color: rgba(0,0,0,.4);
}
.exampleBox .simpleCopy:hover{
    background-color: rgba(0,0,0,.03);
    color: rgba(0,0,0,.4);
}

.paramBox{
    padding:20px 20px;
    background-color: rgba(0,0,0,.025);
}
.paramBox .textBold{
    color: var(--dark-warning-color);
    font-size: 16px;
}
.paramBox .textDanger{
    color: var(--danger-flat)
}

.exampleBox pre {
    white-space: pre-wrap;       /* css-3 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
}
.exampleHeader{
    margin-bottom: 10px;
    padding: 5px;
    border-bottom: 1px solid rgba(0,0,0,.1);
}
.exampleBoxItem{
    margin-bottom: 10px;
    line-height: 20px;
}
.exampleBoxNumber{
    width: 100px;
    background-color: rgba(0,0,0,.2);
    margin-right: 10px;
    padding:5px;
    border-radius: 50%;
}

.codeTitle{
    display: flex;
}
.codeTitle .tag{
    margin-left: 10px;
    padding: 0px;
}