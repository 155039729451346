.aboutBanner{
    background-size: cover;
    background-color: #00142c;
    margin: auto;
    margin-top: 60px;
    min-height: calc(100vh - 60px);
    padding: 20px;
    display: flex;
}
.aboutBannerContainer{
    margin: auto;
}
.aboutBanner .articleTitle{
    font-size: 8rem;
    padding: 20px;
    width: fit-content;
    margin: auto;
    margin-top: 100px;
    margin-bottom: 40px;
}

.aboutBanner .logoIcon{
    width: 10rem;
    /* margin-top: 20px; */
}
.aboutBanner .logoText{
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
    margin: auto;
    color: white;
    font-size: 5rem;
    margin-bottom: 40px;
}
.logoHolder{
    display: block;
}

.bannerText {
    font-size: 1.2rem;
    margin:auto;
    color: var(--dark-grey-color);
}
.bannerInfo .largeText {
    color: var(--vdark-grey-color);
    font-size: 1.4rem;
    margin-bottom: 20px;
}
.aboutBanner .buttonLinks{
    max-width: 600px;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 60px;
    display: grid;
    gap:20px;
    grid-template-columns: 1fr 1fr;
}
.aboutBanner .buttonLinks .btn{
    width: auto;
}
.btnHow p{
    color:white;
}
.btnStart p{
    color:white;
}
.btnHow{
    border: 3px solid #663dff
}
.btnStart {
    border: 3px solid #663dff
}
.btnHow:hover{
    background-color: #663dff;
}
.btnStart:hover{
    background-color: #663dff;
}

.bannerInfo{
    margin: auto;
    padding: 30px;
    max-width: 600px;
    background-color: rgb(230, 230, 230);
    border-radius: 5px;
    text-align: left;
}
.aboutBanner .btn p {
    font-size: 1.6rem;
    padding: 10px;
    font-weight: 700;
}

.bannerGrid{
    grid-template-rows: 1fr 1fr 1fr;
    padding: 10px;
    padding-top: 60px;
    padding-bottom: 40px;
    background-color: rgb(245,245,245);
}

.bannerGridTitle{
    margin: 40px auto;
    font-size: 2rem;
    border-radius: 30px;
    padding: 15px;
    width: fit-content;
    color:var(--vdark-grey-color);
}

.cardGrid{
    padding-left: 10px;
    padding-right: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-top: 50px;
    padding-bottom: 50px;
    gap: 40px;
}
.infoCard{
    position: relative;
    font-size: 1rem;
    color: var(--mid-grey-color);
    background-color: #e5e2f1;
    text-align: left;
    margin-bottom: 20px;
    max-width: 500px;
    margin: auto;
    box-shadow:5px 5px 10px 0px rgba(0,0,0,.2);
}
.imageCard{
    font-size: 6rem;
    justify-content: center;
    margin: auto;
}
.cardTitle{
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    text-align: center;
    padding: 20px;
    background-color: #663dff;
    font-size: 1.4rem;
    color: white;
    border-bottom: 1px solid rgba(0,0,0,.1);
}

.cardTitle p{
    display: flex;
    margin: auto;
    width: fit-content;
    margin: auto;
    align-items: center;
    margin-top: 0px;
}

.textIcon{
    border-radius: 5px;
    margin-right: 5px;
    padding: 5px;
}

.cardBody{
    padding: 20px;
    padding-top: 0px;
    color: var(--dark-grey-color);
    color: rgb(20,20,20);
    font-size: 1.1rem;
    line-height: 30px;
    margin-bottom: 30px;
}
.infoCard a{
    position: absolute;
    bottom: 0;
    padding-bottom: 20px;
    font-size: 20px;
    display: flex;
    padding: 20px;
}
.featureList{
    width: auto;
    display: grid;
    text-align: left;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: 1fr;
    gap: 20px;
    margin: auto;
    margin-bottom: 80px;
    padding: 0px;
    padding-bottom: 100px;
    padding-top: 50px;
    max-width: 1400px;
}
.featureList li{
    box-shadow:2px 2px 10px 0px rgba(0,0,0,.2);
    padding: 15px;
    background-color: rgb(245,245,245);
    background-color: #040c15;
    color:rgb(240,240,240);
    display: flex;
}
.featureList li:hover{
    transform: rotate(5deg)
}
.bannerList{
    padding: 20px;
    background-color: #001c3d;
}
.bannerList .bannerGridTitle{
    color:white;
    padding-top: 50px;
}
.bannerList ul{
    margin: auto;
}
.listIcon{
    color: var(--get-color);
    margin-right: 20px;
}

.cardLink{
    justify-self: end;
}

.shadow {
    height: 15px;
    width: 80px;
    background-color: rgb(200,200,200);
    border-radius: 50%;
    margin: auto;
}
#btnSparse{
    display: none;
}
@media (max-width: 2000px){
    .aboutBanner .logoIcon{
        width: 8rem;
    }
    .aboutBanner .logoText{
        font-size: 4rem;
    }
    .aboutBanner .btn p {
        font-size: 1.4rem;
    }
    .bannerText {
        font-size: 1.2rem;
    }
    .bannerInfo .largeText {
        font-size: 1.3rem;
    }
}
@media (max-width: 950px){
    .featureList{
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width: 800px){
    .cardGrid{
        grid-template-columns: 1fr;
    }
    .imageCard{
        display: none;
    }
}

@media (max-width: 615px){
    .featureList{
        grid-template-columns: 1fr;
    }
    .bannerGridTitle{
        font-size: 1.8rem
    }
}

@media (max-width: 515px){
    .aboutBanner .btn p {
        font-size: 1.4rem;
    }
    #btnSparse{
        display: grid;
    }
    #btnVerbose{
        display: none;
    }
}