.navBar{
    display: flex;
    position: fixed;
    top:0;
    left:0;
    right: 0;
    height:60px;
    z-index: 20;
    background-color: white;
    border-bottom: 1px solid var(--border-grey-color);
    color: var(--mid-grey-color);
}
.navBarLinks, .navContainer, .leftNavLinks{
    display: flex;
    align-items: center;
}
.navContainer{
    padding-left: 30px;
    padding-right: 30px;
    width:100%;
    display: flex;
}
.rightNavLinks{
    margin-left: auto;
}
.rightNavLinks ul{
    display: flex;
}
.navItem{
    padding-right: 25px;
}

.navLogo{
    cursor: pointer;
    padding: 5px;
    padding-top: 10px;
    border-radius: 50px;
    text-align: center;
}
.navLogo img{
    /* width: 30px; */
    cursor: pointer;
    width: 110px;
    padding: 0px;
}

.navLogo span{
    display: table;
    margin: 0 auto
}

.userIdentity{
    color: var(--dark-grey-color);
    font-weight: 600;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 5px;
}

.userIdentity:hover{
    background-color: var(--light-grey-color);
    border-radius: 3px;
}

.userIcon{
    text-align: center;
    color:white;
    background-color: var(--dark-grey-color);
    border-radius: 50%;
    min-width: 30px;
    max-width: 30px;
    min-height: 30px;
    max-height: 30px;
    margin-right: 10px;
}

.userIcon p{
    margin: 0px;
    margin-top: 5px;
}

.navBar .activeRoute:hover{
    color: var(--primary-color)
}

.loginLink{
    border-right: 2px solid var(--term-grey);
    margin-right: 25px;
}

.activeRoute{
    color: var(--primary-color);
}

.hamburger{
    display: none;
    margin-left: auto;
    color: var(--dark-grey-color);
    font-size: 28px;
    padding: 2px;
    cursor: pointer;
}

@media (max-width: 850px){
    .rightNavLinks, .leftNavLinks .navBarLinks{
        display: none;
    }
    .hamburger{
        display: block;
    }
}