.apiSchema{
    margin: 20px 0px;
}
.apiSchema .exampleBox {
    margin: 0px;
    max-height: 400px;
    overflow-x: auto;
}
.apiSchema h4{
    /* margin-top: 10px; */
    margin-bottom: 0px;
    color: var(--dark-warning-color);
    background-color: var(--warning-highlight);
    padding: 10px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom: 1px solid var(--warning-color);
    display: flex;
    align-items: center;
}
.apiSchema h4 .icon{
    margin-right: 10px;
}