.methodInfo{
    padding-left: 20px;
    border-left: 2px solid rgb(230,230,230);
    margin-left: 10px;
    padding-bottom: 10px;
}

.routeMethod .methodTag .icon{
    float: right;
    cursor: pointer;
    /* background-color: rgba(0,0,0,.1); */
    border-radius: 50px;
}
.routeMethod .methodTag .icon:hover{
    background-color: rgba(0,0,0,.1);
}

.getTitle{
    background-color: var(--success-highlight);
    padding: 5px;
    padding-left: 10px;
    color: var(--success-color);
    border-left: 4px solid var(--success-color);
}
.postTitle{
    background-color: var(--primary-highlight);
    padding: 5px;
    padding-left: 10px;
    color: var(--primary-color);
    border-left: 4px solid var(--primary-color);
}
.putTitle{
    background-color: var(--warning-highlight);
    padding: 5px;
    padding-left: 10px;
    color: var(--dark-warning-color);
    border-left: 4px solid var(--dark-warning-color);
}
.deleteTitle{
    background-color: var(--danger-highlight);
    padding: 5px;
    padding-left: 10px;
    color: var(--danger-color);
    border-left: 4px solid var(--danger-color);
}

@media (max-width: 500px){
    .methodInfo{
        padding-left: 0px;
        margin-left: 0px;
        border-left: 0px;
    }
    
}