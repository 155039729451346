.feedItem{
    padding-top: 10px;
    height: 4rem;
    /* display: grid; */
    /* grid-template-columns: 1fr 1fr 1fr 1fr; */
    border-bottom: 1px solid var(--border-grey-color);
}
.feed{
    width: 100%;
}
.feedText{
    margin:auto;
}
.oddRow{
    background-color: rgba(0,0,0,0.035);
}
.routeCol{
    overflow-x: auto;
}
.activityFeed{
    overflow-x: auto;
}
@media (max-width: 850px){
    .feed{
        /* font-size: .8rem; */
    }
    .userCol{
        display: none;
    }
}