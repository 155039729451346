.appIndexTitle{
    display: flex;
}
.appIndexHeader{
    border-bottom: 1px solid rgba(0,0,0,0.1);
    padding-top: 20px;
    padding-bottom: 20px;
}
.appIndex .articleBody{
    padding-top: 10px;
}
.appIndexHeader h3{
    margin: 0px;
    margin-top: 10px;
}
.appIndexHeader p {
    margin: 10px 0px;
}
.appIndexTitle .tag{
    height: fit-content;
    align-self: flex-end;
    margin-bottom: 5px;
    margin-left: 10px;
}
.appIndexTitle .articleTitle{
    margin-bottom:10px;
}
.appSelect{
    /* height: 80px; */
    max-width: 9rem;
    min-width: 9rem;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
}
.routeSelect{
    display: none;
}
.tagTitle{
    display: flex;
}
.bigLink{
    font-size: 1.6rem;
    font-weight: 700;
}
.largeIcon{
    font-size: 1.6rem;
}

.appIndex .pageNavContainer{
    margin-top: 40px;
}
.appDesc{
    padding-left: 15px;
}
@media (max-width: 850px){
    .appDesc{
        padding-left: 0px;
    }
    .routeSelect{
        display: block;
    }
    .tagTitle{
        display: block;
    }
}
@media (max-width: 500px){
    .appSelect{
        /* height: 80px; */
        max-width: 6rem;
        min-width: 6rem;
    }
}