:root {
  --mid-grey-color: rgb(100,100,100);
  --mid-grey-highlight: rgb(120,120,120);
  --border-grey-color: rgb(230,230,230);
  --mid-light-grey-color: rgb(223,223,223);
  --dark-grey-color: rgb(80,80,80);
  --light-grey-color: rgb(240,240,240);
  --vdark-grey-color: rgb(50,50,50);
  --vvdark-grey-color: rgb(20,20,20);
  --link-hover-color: rgb(245,245,245);
  --primary-color: rgb(70, 149, 253);
  /* --primary-color: #1299fa; */
  --post-color: rgb(87, 148, 227);
  --primary-color-light: rgb(104, 169, 255);
  /* --primary-color-light: #3daeff; */
  --primary-dark-color: rgb(60, 121, 200);
  --success-color: rgb(82, 136, 31);
  --get-color: rgb(111, 176, 46);
  --success-highlight: rgb(238, 252, 228);
  --primary-highlight: rgb(235, 241, 252);
  --primary-highlight-hover: rgb(220, 228, 241);
  --warning-highlight: rgb(255, 243, 222);
  --warning-color: rgb(245, 188, 88);
  --put-color: rgb(228, 173, 36);
  --dark-warning-color: rgb(183, 132, 46);
  --darker-warning-color: rgb(152, 109, 36);
  --warning-border: rgb(255, 243, 222);
  --danger-color: rgb(225, 55, 52);
  --delete-color: rgb(222, 73, 71);
  --danger-flat: rgb(253, 96, 49);
  --danger-flat-dark: rgb(217, 79, 37);
  --danger-flat-light: rgb(255, 120, 79);
  --danger-highlight: rgb(247, 229, 229);
  --turqoise: rgb(91, 220, 160);
  --turqoise-light: rgb(108, 238, 177);
  --purple-color:#663dff;


  --term-bg: #1d1f21;
  --term-header: #3b3f43;
  --term-header-highlight: #43474b;
  /* --term-carrot: rgb(227, 165, 66); */
  --term-carrot: rgb(180, 126, 245);
  --term-jelly: rgb(213, 209, 96);
  --term-ivy: rgb(132, 160, 255);
  --term-royal: rgb(88, 130, 209);
  --term-white: rgb(220, 220, 220);
  --term-red: rgb(202, 82, 82);
  --term-grey: rgb(100, 100, 100);

  /* --term-carrot: rgb(215, 195, 60);
  --term-jelly: rgb(144, 246, 245);
  --term-ivy: rgb(75, 175, 102);
  --term-royal: rgb(90, 138, 226);
  --term-white: rgb(245,245,245);
  --term-grey: rgb(220, 220, 220); */
}
.successText{
  color: var(--success-color);
  background-color: var(--success-highlight);
}
.primaryText{
  color: var(--primary-color);
  background-color: var(--primary-highlight);
}
.warningText{
  color: var(--dark-warning-color);
  background-color: var(--warning-highlight);
}
.dangerText{
  color: var(--danger-flat);
  background-color: var(--danger-highlight);
}
.titleButton{
  background-color: var(--dark-grey-color);
  color:white !important;
  width: fit-content;
  padding: 20px;
  padding-left: 10px;
  padding-right: 10px;
  /* border-radius: 50px; */
}
.navText {
  font-size: 15px;
  cursor: pointer;
}
.date {
  font-size: 14px;
  color: var(--mid-grey-color);
  background-color: var(--light-grey-color);
  text-align: center;
  padding: 5px;
  border-radius: 5px;
  width: fit-content;
}
.navText:hover, a:hover{
  color:var(--vdark-grey-color);
}
.navTop{
  padding-top: 60px;
}
.appLink {
  color: var(--primary-color);
  font-weight: 600;
  cursor: pointer;
}
.disabledLink{
  color: var(--mid-grey-highlight);
  font-weight: 600;
}

.dangerLink {
  color: var(--danger-flat);
}

.inlineButton:hover, .inlineBtnSelected{
  background-color: var(--light-grey-color);
  border-radius: 3px;
  cursor: pointer;
}

a {
  text-decoration: none;
  color:var(--mid-grey-color);
  font-size: 15px;
}
hr{
  background-color: var(--border-grey-color);
  color: var(--border-grey-color);
  border: 0px;
  border-bottom: 1px solid var(--border-grey-color);
}
input {
  border: 1px solid var(--mid-grey-color);
}
input:focus, select:focus{
  outline: none !important;
  border: 3px solid var(--primary-color-light);
}

.appLink:hover {
  color: var(--primary-dark-color)
}

.dangerLink:hover {
  color: var(--danger-flat-dark)
}

.textAccent{
  color:var(--dark-warning-color)
}
.textBold {
  font-weight: 800;
}
.textLight {
  color: var(--mid-grey-highlight)
}
.textEmphasize {
  font-weight: 600;
  color: var(--danger-flat)
}
.textDanger {
  color: var(--danger-flat);
}

.inlineFlex{
  display: flex;
}

.inlineTable{
  display: grid;
  gap:20px;
  grid-template-columns: 1fr 1fr;
  margin-top: auto;
  margin-bottom: auto;
}
.inlineTable p{
  align-self: center;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul {
  list-style-type: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.material-symbols-outlined{
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 48
}

.hide {
  display: none !important;
}