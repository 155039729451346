.appDoc {
    background-color: rgb(250,250,250);
}
.articleLinks a{
    margin-right: 5px;
}
.appIndexTitle {
    display: flex;
    align-items: center;
}
.apiDocHeader .appIndexTitle{
    margin-bottom: 20px;
}
.apiDocHeader{
    background-color: #f0edfa;
    /* background-color: rgb(244, 241, 252); */
    padding: 20px;
    margin-bottom: 20px;
    margin-top: 20px;
    box-shadow: 2px 2px 10px 0px rgba(0,0,0,0.2);
}
.apiDocHeader h2 {
    display: flex;
    margin: 0px;
    padding: 0px;
}
.apiDocHeader p{
    margin: 10px 0px;
    padding: 0px 20px;
}
.apiDocHeader .articleLinks {
    margin-bottom: 0px;
}
.apiDocHeader .icon {
    margin-right: 10px;
    color: #3700ff;
}