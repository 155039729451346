.btn{
    border-radius: 3px;
    cursor: pointer;
    width: fit-content;
    text-align: center;
}
.btn p{
    margin: 0px;
}
.btn .icon{
    font-size: inherit;
    line-height: inherit;
    padding-right: 5px;
    /* align-self: flex-end; */
}
.buttonTextBox{
    display: flex;
    margin:auto;
    justify-content: center;
}
.btnPrimary {
    background-color: var(--primary-color);
}
.btnAlt {
    background-color: var(--turqoise);
}
.btnAlt:hover{
    background-color: var(--turqoise-light);
}
.btnAlt p, .btnDanger p{
    color: white;
}
.btnDanger {
    background-color: var(--danger-flat);
}
.btnDanger:hover {
    background-color: var(--danger-flat-light);
}

.btnPrimary:hover{
    background-color: var(--primary-color-light)
}
.btnDark {
    background-color: var(--mid-grey-color);
}
.btnDark:hover {
    background-color: var(--mid-grey-highlight);
}
.btnLight {
    background-color: var(--mid-light-grey-color);
}
.btnLight:hover {
    background-color: var(--border-grey-color);
}
.btnPrimary p, .btnDark p{
    color: white;
}
.btnMedium{
    padding: 10px;
    font-weight: 600;
}
.btnIcon{
    margin-right: 10px;
}